import { Collapse } from 'designsystem/components/2-molecules/Collapse';
import { BackgroundContainerFeature } from 'features/Modules/BackgroundContainer';
import { fireAdobeEvent } from 'helpers/adobeEvent';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { ModuleFeature } from '../DynamicModule';

export const CollapseFeature: React.FC<Content.Collapse> = ({
	documentType,
	content,
	headingLevel = 'h2',
	isSubCollapse = false,
	noBackground = false,
	...props
}) => {
	const { heading, anchor } = content.properties ?? {};
	const isCopy = documentType?.includes('DataCopy');

	const [adobeEventCount, setAdobeEventCount] = useState(0);
	const [isOpen, setIsOpen] = useState(false);

	const handleClick = () => {
		const newIsOpen = !isOpen;
		setIsOpen(newIsOpen);

		const mainContent: HTMLElement = document.querySelector('#main');
		if (mainContent.dataset.readspeaker === 'active') return;
		const adobePayload = {
			count: adobeEventCount + 1,
			type: 'text with link',
			name: heading,
		};
		if (newIsOpen) {
			fireAdobeEvent('accordion_open', adobePayload);
			setAdobeEventCount(adobeEventCount + 1);
		}
	};

	const router = useRouter();

	// For nextjs links and initial render
	useEffect(() => {
		const anchorElement = window.location.hash && document.getElementById(window.location.hash.slice(1));
		if (anchorElement && window.location.hash.slice(1) === anchor) {
			handleClick();
			anchorElement.focus();
		}

		const onHashChange = (url: string) => {
			if (url.split('#')[1] === anchor) {
				const collapseBtn = document.getElementById(anchor);
				handleClick();
				collapseBtn.focus();
			}
		};

		router.events.on('hashChangeStart', onHashChange);
		return () => {
			router.events.off('hashChangeStart', onHashChange);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// For a-tag links
	useEffect(() => {
		// TODO if we can use nextjs links in RTE then we dont need this
		const onHashChange = (event) => {
			if (event.newURL.split('#')[1] === anchor) {
				handleClick();
				// TODO This doesnt open collapse when clicking link twice, prolly because the hash doesnt change when i click it again
			}
		};

		window.addEventListener('hashchange', onHashChange);
		return () => {
			window.removeEventListener('hashchange', onHashChange);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!anchor) {
			setIsOpen(false);
		}
	}, [router, anchor]);

	const currentHeadingLevel = isCopy ? (headingLevel === 'h2' ? 'h3' : headingLevel) : headingLevel;

	const childProps = { headingLevel: currentHeadingLevel === 'h3' ? 'h4' : 'h3', isSubCollapse: true, noBackground: true };
	return (
		<BackgroundContainerFeature enableBackground={!noBackground}>
			<Collapse
				header={heading}
				headingLevel={currentHeadingLevel}
				anchor={anchor}
				isSubCollapse={isSubCollapse}
				isOpen={isOpen}
				handleClick={handleClick}
				{...props}
			>
				{content.properties.content.map((c, index) => (
					<ModuleFeature contentModule={c} key={index} additionalProps={childProps} />
				))}
			</Collapse>
		</BackgroundContainerFeature>
	);
};
