import classNames from 'classnames';
import { Heading } from 'components/1-atoms/Heading';
import styles from './CollapseCollection.module.scss';

export interface CollapseCollectionProps {
	heading?: string;
	noMargin?: boolean;
	collapses: React.ReactNode[];
}

export const CollapseCollection: React.FC<CollapseCollectionProps> = ({ heading, noMargin, collapses }) => {
	return (
		<div className={classNames(styles.CollapseCollection, noMargin && styles.CollapseCollection___noMargin)}>
			{heading ? (
				<Heading headingLevel="h2" style="md">
					{heading}
				</Heading>
			) : null}
			{collapses}
		</div>
	);
};
