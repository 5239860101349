import { CollapseCollection } from 'designsystem/components/2-molecules/CollapseCollection';
import { BackgroundContainerFeature } from 'features/Modules/BackgroundContainer';
import { CollapseFeature } from '../Collapse/CollapseFeature';

export const CollapseCollectionFeature: React.FC<Content.CollapseCollection> = ({ content, settings, noBackground = false }) => {
	const { heading, collapses } = content?.properties ?? {};
	const { noTopMargin } = settings?.properties ?? {};

	return (
		<BackgroundContainerFeature noTopMargin={noTopMargin} enableBackground={!noBackground}>
			<CollapseCollection
				heading={heading}
				collapses={collapses?.map((collapse, index) => (
					<CollapseFeature
						key={index}
						{...collapse}
						{...{ 'data-tracking-id': 'collapse' }}
						headingLevel={heading ? 'h3' : 'h2'}
						noBackground={true}
					/>
				))}
			/>
		</BackgroundContainerFeature>
	);
};
